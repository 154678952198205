/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import dynamic from "next/dynamic";

import styles from "./LandingPageLayout.module.scss";

const AppFooter = dynamic(() => import("components/AppFooter"));

export interface LayoutProps {
  children: React.ReactNode;
}

const LandingPageLayout = ({ children }: LayoutProps) => {
  return (
    <div className={styles.container}>
      <div id="portal"></div>
      <section>
        {children}
        <section className={styles.footerSection}>
          <AppFooter displayDesktopMenu />
        </section>
      </section>
    </div>
  );
};

export default LandingPageLayout;
